.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.contact-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.contact-subtitle {
	width: 100% !important;
}

.contact-title hr{
	height: 5px;
	background-color: #004AAD;
	border-radius: 10px;
	border: none;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
}

.contactForm-container{
	display: flex;
	margin-top: 80px;
	justify-content: space-evenly;
}

.contactForm-left{
	display: flex;
	flex-direction: column;
}

.contactForm-inputs{
	width: 400px;
	height: 50px;
	border: none;
	outline: none;
	padding-left: 20px;
	font-weight: 500;
	color: #65656d;
	background-color: #f2f0f0;
	border-radius: 50px;
}

.contactForm-left textarea{
	height: 130px;
	padding-top: 15px;
	border-radius: 20px;
}

.contactForm-inputs:focus{
	border: 2px solid #004AAD;
}

.contactForm-left button{
	display: flex;
	padding: 15px 30px;
	align-items: center;
	font-size: 20px;
	font-weight: 500;
	background-image: linear-gradient(270deg, #0251b7, #0392ff);
	color: white;
	border-radius: 50px;
	cursor: pointer;
	transition: 0.5s;
	gap: 10px;
	border: none;
}

.contactForm-left button:hover{
	transform: scale(1.10);
}

.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 80px;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
