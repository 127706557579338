@import "../../../data/styles.css";

.works-body {
    margin-bottom: 10px;
    margin-left: 20px;
}

.work {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    width: 400px;
}

.work-image {
    height: 30px;
    width: 35px;
    border-radius: 50%;
    outline: 6px solid white;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.work-title {
    font-size: 15px;
    font-weight: 700;
    padding-left: 20px;
    margin-top: -3px;
    color: var(--secondary-color);
}

.work-subtitle {
    font-size: 12px;
    color: var(--secondary-color);
    padding-top: 5px;
    padding-left: 20px;
}

.work-description {
    padding-top: 10px;
    padding-left: 20px;
}

.work-description ul {
    margin: 0;
    padding-left: 20px;
}

.work-description li {
    font-size: 14px;
    color: var(--secondary-color);
    margin-bottom: 5px;
}

.work-duration {
    font-size: 12px;
    padding-top: 5px;
    padding-left: 20px;
    color: var(--tertiary-color);
}

@media screen and (max-width: 480px) {
    .work {
        width: 300px;
    }

    .work-image {
        height: 30px;
        width: 35px;
    }

    .work-title {
        font-size: 14px;
    }

    .work-subtitle {
        font-size: 11px;
    }

    .work-description li {
        font-size: 12px;
    }

    .work-duration {
        font-size: 11px;
    }
}